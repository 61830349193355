//首重列表
export const firstWeightListCols = [
  {
    label: '国家/地区名称',
    prop: 'countryName'
  },
  {
    label: '重量范围(g)',
    prop: 'weightRange',
    slotName: 'weightRangeSlot'
  },
  {
    label: '首重费用(￥)',
    prop: 'firstFreight',
    slotName: 'firstFreightSlot'
  },
  {
    label: '续重单位价格(￥)',
    prop: 'continuedFreight',
    slotName: 'continuedFreightSlot'
  },
  {
    label: '挂号费(￥)',
    prop: 'registrationFee'
  },
  {
    label: '紧急情形附加费(￥)',
    prop: 'emergencySurcharge'
  }
]

export const sameWeightListCols = [
  {
    label: '国家/地区名称',
    prop: 'countryName'
  },
  {
    label: '重量范围(g)',
    prop: 'weightRange',
    slotName: 'weightRangeSlot'
  },
  {
    label: '费用(￥)',
    prop: 'freight'
  },
  {
    label: '挂号费(￥)',
    prop: 'registrationFee'
  },
  {
    label: '紧急情形附加费(￥)',
    prop: 'emergencySurcharge'
  }
]
